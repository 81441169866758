exports.components = {
  "component---src-pages-alelo-tsx": () => import("./../../../src/pages/alelo.tsx" /* webpackChunkName: "component---src-pages-alelo-tsx" */),
  "component---src-pages-cases-laundry-tsx": () => import("./../../../src/pages/cases-laundry.tsx" /* webpackChunkName: "component---src-pages-cases-laundry-tsx" */),
  "component---src-pages-cases-tsx": () => import("./../../../src/pages/cases.tsx" /* webpackChunkName: "component---src-pages-cases-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-indicacao-tsx": () => import("./../../../src/pages/indicacao.tsx" /* webpackChunkName: "component---src-pages-indicacao-tsx" */),
  "component---src-pages-partners-tsx": () => import("./../../../src/pages/partners.tsx" /* webpackChunkName: "component---src-pages-partners-tsx" */),
  "component---src-pages-politica-de-privacidade-tsx": () => import("./../../../src/pages/politica-de-privacidade.tsx" /* webpackChunkName: "component---src-pages-politica-de-privacidade-tsx" */),
  "component---src-pages-termos-de-uso-tsx": () => import("./../../../src/pages/termos-de-uso.tsx" /* webpackChunkName: "component---src-pages-termos-de-uso-tsx" */)
}

