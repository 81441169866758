import 'firebase/database'
import 'firebase/analytics'
import './src/styles/reset.css'
import './src/styles/collapsible.css'
import './src/styles/flickity.css'
import './src/styles/politica-de-privacidade.css'
import './src/styles/hamburger_menu.css'
import './src/styles/partners_hamburger_menu.css'
import './src/styles/index.css'
import './src/styles/partners.css'
import './src/styles/termos-de-uso.css'
import './src/styles/install-app.css'
import './src/styles/qrcode_modal.css'
import './src/styles/mailchimp-contact-form.css'
import './src/styles/indicacao.module.css'
import './src/styles/alelo.module.css'
import './src/styles/sucess-histories.css'
import './src/styles/cases.css'
import './src/styles/introduction-case.css'
import './src/styles/objectives-achieved-case.css'
import './src/styles/result-delivered-case.css'
import './src/styles/acceleration-purchase-graphic.css'
import './src/styles/sucess-measurements.css'
import './src/styles/be-our-patner-cases.css'
import './src/styles/sucess-histories-in-case.css'
import './src/styles/hamburger_menu_cases.css'
import './src/styles/introduction-case-laundry.css'
import './src/styles/result-delivered-case-laundry.css'
import './src/styles/result-with-right-buyers.css'
import './src/styles/increased-recurrence.css'
import './src/styles/sucess-measurements-laudry.css'

const React = require("react")
const {initializeApp} = require("firebase/app")

const firebaseConfig = {
    apiKey: "AIzaSyDcBBmT0JlaQI0g0G6HjF93E-cHC26_D8s",
    authDomain: "dinerama-2912c.firebaseapp.com",
    databaseURL: "https://dinerama-2912c-default-rtdb.firebaseio.com",
    projectId: "dinerama-2912c",
    storageBucket: "dinerama-2912c.appspot.com",
    messagingSenderId: "749349398914",
    appId: "1:749349398914:web:ed0d1fad799d6049b6479b",
    measurementId: "G-H0509LLPJ3",
}

const BaseElement = ({element}) => {
    initializeApp(firebaseConfig)
    return element
}

const Wrapper = ({element}) => <BaseElement element={element}/> // required to allow hooks

export const wrapRootElement = Wrapper
